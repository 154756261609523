import React, { useEffect } from 'react'
import {DELIMITER} from '../resources/constants'

function LastSearchedGenes(props) {

    const MAX_COUNT_OF_LATEST_GENES_TO_SHOW = 5;
    const EVEN_ROW_CLASS = 'evenRow';
    const ODD_ROW_CLASS = 'oddRow';
    const GENEID_QUERYSTRING_FIELD = 'geneid';
    const regex = new RegExp(/\s/g)

    const [lastSearchedGene, setLastSearchGene] = React.useState();
    const [lastSearchedGeneList, setLastSearchedGeneList] = React.useState([]);
    const titleText = props.isFirstTabSelected || props.isImmunoData ? "Recently Searched Genes" : props.isSecondTabSelected ? "Two Gene Search Examples" : "Multi Gene Search Examples";
    
    useEffect(() => {
        if (props.recentGeneList && props.recentGeneList.length > 0) {
            setLastSearchedGeneList(props.recentGeneList);
        }
    }, [props.recentGeneList]);

    useEffect(() => {
        if (props.socketInst) {
            props.socketInst.on('lastgene', lastgene => {
                setLastSearchGene(lastgene);
            });
        }

    }, [props.socketInst]);

    useEffect(() => {
        if (lastSearchedGene) {
            
            let searchedGenes = [...lastSearchedGeneList];
            if (searchedGenes.length >= MAX_COUNT_OF_LATEST_GENES_TO_SHOW) {
                searchedGenes.pop();
            }
            searchedGenes.unshift(lastSearchedGene);
            setLastSearchedGeneList(searchedGenes);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastSearchedGene]);
    
    return (
        <div className="searchedGenesContainer">
            <div className="searchedGenesHeader">
                {titleText}
            </div>
            {lastSearchedGeneList && lastSearchedGeneList.length > 0 &&
                lastSearchedGeneList.map((gene, i) => {
                    let classname = EVEN_ROW_CLASS;
                    i % 2 === 0 ? classname = EVEN_ROW_CLASS : classname = ODD_ROW_CLASS;
                    
                    if(props.isImmunoData && (!gene.includes(DELIMITER))){
                        return <div key={i}><a className={classname} href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_IMMUNO_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + gene.replace(regex, '')} target="_blank" rel="noreferrer">{gene}</a></div>
                    } else if (props.isFirstTabSelected && !gene.includes(DELIMITER)) {
                        return <div key={i}><a className={classname} href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_SINGLE_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + gene.replace(regex, '')} target="_blank" rel="noreferrer">{gene}</a></div>
                    } else if (props.isSecondTabSelected){
                        return <div key={i}><a className={classname} href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_DUAL_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + gene.replace(regex, '')} target="_blank" rel="noreferrer">{gene}</a></div>
                    } else if (!props.isFirstTabSelected && !props.isSecondTabSelected && !props.isImmunoData) {
                        return <div key={i}><a className={classname} href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_MULTI_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + gene.replace(regex, '')} target="_blank" rel="noreferrer">{gene}</a></div>
                    }
                    
                    
                })   
            }
        </div>
    )
}

export default LastSearchedGenes
