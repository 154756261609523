import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import FacebookSVG from '../resources/svg/facebook-square-brands.svg';
import LinkedInSVG from '../resources/svg/linkedin-brands.svg';
import TwitterSVG from '../resources/svg/twitter-square-brands.svg';
import routes from '../resources/json/routes.json';

function Footer(props) {

    const ANIMATED_CLASS = 'footerContainer';
    const NONANIMATED_CLASS = 'footerContainerNoAnim';

    const [footerContainerClass, setFooterContainerClass] = React.useState();

    useEffect(() => {
        if (props.useKeyframeAnim) {
            setFooterContainerClass(ANIMATED_CLASS);
        } else {
            setFooterContainerClass(NONANIMATED_CLASS);
        }
    }, [props.useKeyframeAnim]);

    return (
        <div className={footerContainerClass}>
            <div id="footerElement">
                <img src={FacebookSVG} className="socialMediaIcons" alt=""/>
                <img src={LinkedInSVG} className="socialMediaIcons" alt=""/>
                <img src={TwitterSVG} className="socialMediaIcons" alt=""/>
                <span className="verticalDivider"/>
                <Link to={routes.client.news} alt="">News</Link>
                <span className="verticalDivider"/>
                <span>&#169; {new Date().getFullYear()}</span>
                <span className="verticalDivider"/>
                v0.1
                <span className="verticalDivider"/>
                <a href="https://moffitt.org" target="_blank" rel="noreferrer">Moffitt Cancer Center</a>
                <span className="verticalDivider"/>
                <a href="https://moffitt.org/legal-statements-and-policies/terms-conditions/" target="_blank" rel="noreferrer">Terms & Conditions</a>
                <span className="verticalDivider"/>
                <a href="http://lab.moffitt.org/wang" target="_blank" rel="noreferrer">Wang Lab</a>
            </div>
        </div>
    )
}

export default Footer
