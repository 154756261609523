import './App.css';
import {Route, useLocation} from 'react-router-dom';
import DevelopmentBanner from './components/DevelopmentBanner';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import News from './components/News';
import Contact from './components/Contact';

function App() {

  const location = useLocation();

  return (
    <div className="col-12" style={{width: '99vw'}}>
        {process.env.REACT_APP_PRODUCTION !== 'true' && process.env.REACT_APP_UAT !== 'true' &&
          <DevelopmentBanner/>
        }
        <Navbar currentPath={location.pathname}/>
        <Route exact path="(/|/home)" component={Home}/>
        <Route path="/about" component={About}/>
        {/* <Route path="/news" component={News}/> */}
        <Route path="/contact" component={Contact}/>
    </div>
  );
}

export default App;
