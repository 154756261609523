import React from 'react';
import Footer from './Footer';

function About() {

    const teamMembers = [
        { name: 'Biwei Cao, MS', role: 'Data Analysis' },
        { name: 'Scott Cukras', role: 'Application Support' },
        { name: 'Amith Murthy, MS', role: 'Application Support' },
        { name: 'Guillermo Gonzalez, PhD', role: 'Application Support' },
        { name: 'Jose Conejo-Garcia, MD PhD', role: 'Co-investigator' },
        { name: 'Xuefeng Wang, PhD', role: 'Principal Investigator' },
    ];

    return (
        <React.Fragment>
            <div style={{width: '60vw', marginLeft: '20vw', marginTop: '20vh'}}>
                <h4 className="textAlignCenter">About</h4>
                <div className="spacer"/>
                <p/>
                The Cancer Gene Prognosis Atlas (CGPA) is a groundbreaking online tool aimed at improving the discovery and validation of gene-centric biomarkers in cancer genomics. By providing extensive analysis capabilities, CGPA addresses the shortcomings of existing databases through multivariable and multi-gene survival models essential for precise prognostic evaluations. It offers an easy-to-navigate platform for researchers and clinicians to investigate the influence of gene expression on clinical outcomes, a process often complicated by the vast and complex data in current cancer transcriptome databases. The unique features of CGPA also support effective gene correlation analyses and the development of custom gene panels, substantially contributing to advancements in cancer research. This work was also partly supported by the State of Florida Bankhead-Coley Cancer Research Program, infrastructure research grant 23B16,
                Moffitt Immunology Innovation Funds, a Miles for Moffitt pilot fund, a National Institute of Health grant, R01DE030493, and by the Biostatistics and Bioinformatics Shared Resource at the H. Lee Moffitt Cancer Center & Research Institute, an NCI designated Comprehensive Cancer Center.  <div className="spacerLg"/>
                <h4 className="textAlignCenter">Team</h4>
                <div className="spacer"/>
                <p/>
                {teamMembers && teamMembers.length > 0 &&
                    teamMembers.map((obj, i) => {
                        return (
                            <React.Fragment key={i}>
                                <div className="row">
                                    <div className="teamNameContainer">{obj.name}</div>
                                    <div className="teamRoleContainer">{obj.role}</div>
                                </div>
                                <p/>
                            </React.Fragment>
                        )
                    })
                }
                <div className="spacerLg"/>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default About
