import axios from 'axios';

class HttpService {

    constructor () {
        this.urlBase = process.env.REACT_APP_NODE_HOST + ':' + process.env.REACT_APP_NODE_PORT;
    }

    get = url => {
        return axios.get(this.urlBase + url);
    };

    post = (url, body) => {
        return axios.post(this.urlBase + url, body);
    };

}

export default new HttpService();