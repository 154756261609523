class AutoSuggestService {

    constructor () {
        this.DEBOUNCE_DELAY = 300;
        this.suggestions = [];
    }

    _suggestItems = (searchValue, searchList, callback) => {
        this.suggestions = searchList.filter(item => item.includes(searchValue));
        callback();
    };

    _debounce = (callback, delay=this.DEBOUNCE_DELAY) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => callback(...args), delay);
        };
    };

    getSuggestions = (searchValue, searchList, callback) => {
        let returnSuggestions = this._debounce(this._suggestItems);
        return returnSuggestions(searchValue, searchList, callback);
    };

}

export default new AutoSuggestService();