import React, { useEffect, useState } from 'react';
import LastSearchedGenes from './LastSearchedGenes';
import MostPopularSearchedGenes from './MostPopularSearchedGenes';
import Footer from './Footer';
import AutoSuggestService from '../services/AutoSuggestService';
import HttpService from '../services/HttpService';
import routes from '../resources/json/routes.json';
import PlaneSVG from '../resources/svg/telegram-plane-brands.svg';
import io from 'socket.io-client';
import CgpaSVG from '../resources/svg/cgpah2.svg';
import MoffittSmSVG from '../resources/svg/moffitt_small.svg';
import {DELIMITER} from '../resources/constants'
import PageTracker from './PageTracker';
import VerticalTab from './VerticalTab';


function Home() {

    const GENE_SUGGESTION_ROW_CONTAINER_ID = 'geneSuggestionRowContainer';
    const GENEID_QUERYSTRING_FIELD = 'geneid';

    const [geneTextField, setGeneTextField] = React.useState('');
    const [geneList, setGeneList] = React.useState();
    const [geneSuggestions, setGeneSuggestions] = React.useState();
    const [showSuggestions, setShowSuggestions] = React.useState(false);
    const [renderPage, setRenderPage] = React.useState(false);
    const [popularGeneList, setPopularGeneList] = React.useState();
    const [recentGeneList, setRecentGeneList] = React.useState();
    const [errorMessage, setErrorMessage] = React.useState('');
    const logoRef = React.createRef();
    const searchIconRef = React.createRef();
    
    const [animate, setAnimate] = useState(true)

    const [isFirstTabSelected, setIsFirstTabSelected] = useState(true);
    const [isSecondTabSelected, setIsSecondTabSelected] = useState(false);
    const [isImmunoData, setIsImmunoData] = useState(false);
    const [genesListToDisplay, setGeneListToDisplay] = useState(recentGeneList);
    const [showSubmit, setShowSubmit] = React.useState(false);

    const [socketInst, setSocketInst] = React.useState();
    
    const regexCommas = new RegExp(",", "g");
    const twoGeneExamples = ["UBE2Z, RNF113A", "VPS37A, DCLRE1B", "TCF7, LAG3"];
    const multiGeneExamples = ["PDCD1, CTLA4, HAVCR2, CXCR5, TOX, SLAMF6", "CD69, TCF7, CCR7, SELL, S1PR1, CD27, CD28"];

    window.addEventListener('click', (e) => {   
        if (document.getElementById(GENE_SUGGESTION_ROW_CONTAINER_ID)) {
            if (!document.getElementById(GENE_SUGGESTION_ROW_CONTAINER_ID).contains(e.target)) {
                setShowSuggestions(false);
            }
        }
    });
    /*
    window.addEventListener('keydown', (e) => {
        if (e.key === 'ArrowDown') {}
        if (e.key === 'ArrowUp') {}
    });
    */

    useEffect(() => {

        const socket = io(process.env.REACT_APP_NODE_HOST + ':' + process.env.REACT_APP_NODE_PORT);
        setSocketInst(socket);
        socket.on('connect', () => {});

        HttpService.get(routes.server.root + routes.server.geneListGet)
            .then(res => {
                setGeneList(res.data.docs);
                setRenderPage(true);
            })
            .catch(err => {
                console.log(err.response);
            });

        HttpService.get(routes.server.root + routes.server.popularGeneListGet)
            .then(res => {
                setPopularGeneList(res.data.geneList);
            })
            .catch(err => {
                console.log(err.response);
            });

        HttpService.get(routes.server.root + routes.server.recentGeneListGet)
            .then(res => {
                let allGenes = res.data.geneList
                setRecentGeneList(allGenes.filter((value, index, self) => self.indexOf(value) === index));
            })
            .catch(err => {
                console.log(err.response);
            });

        return () => { socket.disconnect() }
    }, []);

    useEffect(() => {
        if (renderPage) {
            // workaround for adding 2 keyframes to PlaneSVG
            setTimeout(function() {
                if (searchIconRef.current) {
                    searchIconRef.current.className = 'searchPlaneIcon2';
                }
            }, 1500);
        }
        
    }, [renderPage, searchIconRef]);

    const onChangeGeneSelection = e => {
        var geneSearchValue = e.target.value.toUpperCase();
        
        // Validation logic for search bar inputs due to tab design
        var inputLength = geneSearchValue.length - 1;
        var commas = geneSearchValue.match(regexCommas);
        const inputPattern = new RegExp("^[^,]* *, *[^,]*$|^[^,\s]* *, *[^,\s]*$")
        const singleTabPattern = new RegExp("^[a-zA-Z0-9]*$")
        const trailingComma = new RegExp(/,$/g)
        const removeCommas = new RegExp(/[,]/i)
        var isValidPattern = geneSearchValue.match(inputPattern) === null ? false : true;
        geneSearchValue = geneSearchValue.replace(/[\|&;.\$%@"<>\(\)\+]/g,"");
        setErrorMessage('')
        
        if (isFirstTabSelected || isImmunoData && geneSearchValue.match(singleTabPattern) === null){
            geneSearchValue = geneSearchValue.replace(/[,]/g,"");
        }
        
        if(isSecondTabSelected && (commas?.length >= 2 || !isValidPattern)){
            geneSearchValue = geneSearchValue.replace(removeCommas, '');
        } else{
            if (geneSearchValue[inputLength] === ',' && geneSearchValue[inputLength -1] ===','){
                geneSearchValue = geneSearchValue.replace(trailingComma, "");
            }
            if (geneSearchValue.includes(",,") || geneSearchValue.includes(", ,")){  
                geneSearchValue = geneSearchValue.replace(/[,]/g,"");
                setErrorMessage("genes should be separated by single comma and gene name")
            } 
        }
         
        setGeneTextField(geneSearchValue);
        setShowSuggestions(true);
        AutoSuggestService.getSuggestions(geneSearchValue, geneList, () => {
            setGeneSuggestions(AutoSuggestService.suggestions);
        });
    };

    const onClickGeneSuggestion = gene => {
        setGeneTextField(gene);
        setShowSuggestions(false);
    };

    const onKeyDownSearchSubmit = e => {
        if (e.key === 'Enter') {
            _submit();
        }
    };

    const onClickSearchSubmit = e => {
        _submit();
    };

    const validateInput = () => {
        
        const MAX_NUMBER_OF_GENES = 10
        const regex = new RegExp(/\s/g)
        const regexTrailingComma = new RegExp(/,$/g)
        const regexGenes = new RegExp("[^,]+","g")

        let error = null
        let validationErrorMessage = ''

        var commas = geneTextField.match(regexCommas)?.length;
        commas = commas ? commas : 0;
        let tabValidation = geneTextField.trim()
        var genes = tabValidation.match(regexGenes);
        
        if (isFirstTabSelected || isImmunoData){
            if (commas > 0){
                error = true;
                validationErrorMessage = `Only one gene can be searched, no comma expected`;
            }
        } else if (isSecondTabSelected){
            if (commas !== 1){
                error  = true;
                validationErrorMessage = `Only two genes can be searched, only one comma expected`;
            }
            if (genes.length !== 2){
                error = true;
                validationErrorMessage =  `Only two genes should be searched, please input the correct number`;
            }
        } else{
            if (commas < 2){
                error = true;
                validationErrorMessage = `Search more than two genes`;
            }
            if (genes.length < 3){
                error = true;
                validationErrorMessage =  `More than two genes should be searched, please input the correct number`;
            } 
        }

        let validatedGeneTextField = geneTextField.replace(regex, '')
        validatedGeneTextField = validatedGeneTextField.replace(regexTrailingComma, '')
        let geneCount = 1

        if (geneTextField.includes(DELIMITER)) {
            geneCount = validatedGeneTextField.split(DELIMITER).length
        }

        if (geneCount > MAX_NUMBER_OF_GENES) {
            error = true
            validationErrorMessage = `Cannot select more than ${MAX_NUMBER_OF_GENES} genes`
        }

        return { validatedGeneTextField, error, validationErrorMessage }
    }

    const _submit = () => {
        const { validatedGeneTextField, error, validationErrorMessage } = validateInput()
        if (error) {
            setErrorMessage(validationErrorMessage)
        } else {
            let url
            socketInst.emit('newsearch', validatedGeneTextField);
            if (isImmunoData){
                url = process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_IMMUNO_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + validatedGeneTextField;
            } else if(isFirstTabSelected){
                url = process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_SINGLE_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + validatedGeneTextField;
            } else if (isSecondTabSelected){
                url = process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_DUAL_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + validatedGeneTextField;
            } else{
                url = process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_MULTI_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + validatedGeneTextField;
            }
            // if (validatedGeneTextField.includes(DELIMITER)) {
            //     url = process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_MULTI_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + validatedGeneTextField;
            // } else {
            //     url = process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_SINGLE_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + validatedGeneTextField;
            // }
            window.open(url, "_blank");
        }
    };

    const resetTabText = () =>{
        setGeneTextField("");
        setShowSubmit(false);
        setErrorMessage('');
    }
    
    useEffect(()=>{
        if(isFirstTabSelected || isImmunoData){
            setGeneListToDisplay(recentGeneList)
        } else if(isSecondTabSelected){
            setGeneListToDisplay(twoGeneExamples)
        } else{
            setGeneListToDisplay(multiGeneExamples)
        }
    }, [isFirstTabSelected, isSecondTabSelected, recentGeneList, isImmunoData])

    useEffect(()=>{
        let validText = geneTextField.trim();
        if(validText.length >= 2){
            setShowSubmit(true)
        } else {
            setShowSubmit(false);
        }
    }, [geneTextField])

    return (
        <div>
        <React.Fragment>
            {!renderPage && 
                <React.Fragment>
                    <div className="circleLoader mainCircleLoader"/>
                    <div className="textAlignCenter mainLoadingText">
                        <span className="blueText">Loading </span>
                        <span className="redText loadingDot">. </span>
                        <span className="yellowText loadingDot">. </span>
                        <span className="greenText loadingDot">.</span>
                    </div>
                </React.Fragment>
            }
            {renderPage &&
                <React.Fragment>
                    <div>
                        <img src={CgpaSVG} className="logoMain" ref={logoRef} alt=""/>
                        <img src={MoffittSmSVG} className="moffittLogoMain" alt=""/>
                    </div>
                    <div className="col-12" id="homePageTitle">
                        <span className="blueText">CANCER </span>
                        <span className="redText">GENE </span>
                        <span className="yellowText">PROGNOSIS </span>
                        <span className="greenText">ATLAS</span>
                    </div>
                    <div id="errorMessage" className="redText">
                        {errorMessage !== '' &&
                            errorMessage
                        }
                    </div>
                    <div className="spacer"/>
                    {/* <div style={{display:'flex', flexDirection:'column'}}> */}
                    <VerticalTab setIsFirstTabSelected={setIsFirstTabSelected} setIsSecondTabSelected={setIsSecondTabSelected} resetTabText={resetTabText} setAnimate={setAnimate} setIsImmunoData={setIsImmunoData}>
                        <div>
                            <div className="row justify-content-center">
                                <input type="text" className={animate ? "geneSearchBar": "geneSearchBarNonAnim"} placeholder={isFirstTabSelected || isImmunoData ? "Gene Name": "Gene Names"} value={geneTextField} onKeyDown={onKeyDownSearchSubmit} onChange={onChangeGeneSelection}/>
                                {showSubmit ?
                                    <img src={PlaneSVG} className="searchPlaneIcon" ref={searchIconRef} alt="Search" onClick={onClickSearchSubmit}/>
                                :
                                <></>
                                }
                                {/* <img src={PlaneSVG} className="searchPlaneIcon" ref={searchIconRef} alt="Search" onClick={onClickSearchSubmit}/> */}
                            </div>
                            {geneTextField && showSuggestions && geneSuggestions && geneSuggestions.length > 0 &&
                                <div className="row justify-content-center" id={GENE_SUGGESTION_ROW_CONTAINER_ID}>
                                    <div className="geneSuggestionContainer">
                                        {geneSuggestions.map((gene, i) => {
                                                return <div className="suggestedGene" key={i} onClick={()=>onClickGeneSuggestion(gene)}>{gene}</div>
                                            })  
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row justify-content-center">
                            <div id={animate ? "geneExamples" : "geneExamplesNonAnim"}>
                                Ex:
                                {isFirstTabSelected || isImmunoData
                                ?
                                <i>
                                    {isFirstTabSelected ? 
                                    <a className="pointer exampleLink" href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT +  '/' + process.env.REACT_APP_SHINY_SINGLE_ROUTE +'/?' + GENEID_QUERYSTRING_FIELD + '=PIK3CA'} rel="noreferrer" target="_blank"> PIK3CA</a> 
                                    :
                                    <a className="pointer exampleLink" href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT +  '/' + process.env.REACT_APP_SHINY_IMMUNO_ROUTE +'/?' + GENEID_QUERYSTRING_FIELD + '=PIK3CA'} rel="noreferrer" target="_blank"> PIK3CA</a> 
                                    }
                                </i>
                                :
                                isSecondTabSelected
                                ?
                                <i>
                                    <a className="pointer exampleLink" href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_DUAL_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=PIK3CA,TP53'} rel="noreferrer" target="_blank"> PIK3CA, TP53</a> 
                                </i>
                                :
                                <i>
                                    <a className="pointer exampleLink" href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_MULTI_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=PIK3CA,TP53,EGFR'} rel="noreferrer" target="_blank"> PIK3CA, TP53, EGFR</a> 
                                </i>
                                } 
                                {isFirstTabSelected || isImmunoData ? <div> </div> : isSecondTabSelected ? <div id="multipleGenesText">Comma-separate two genes</div> : <div id="multipleGenesText">Comma-separate multiple genes</div>}
                            </div>
                        </div>
                    </VerticalTab>
                    
                    <div className="spacer"/>
                    <div className="row justify-content-center">
                        <LastSearchedGenes socketInst={socketInst} 
                        recentGeneList={genesListToDisplay}
                        isFirstTabSelected={isFirstTabSelected} isSecondTabSelected={isSecondTabSelected}
                        animate={animate} isImmunoData={isImmunoData}
                        />
                    </div>
                    {/* </div> */}
                </React.Fragment>
            }
                <PageTracker/>
                <Footer useKeyframeAnim={animate}/>       
        </React.Fragment>
        {/* {!animate &&     
        <>
            <PageTracker/>
            <Footer useKeyframeAnim={false}/>
        </>
    
        } */}
        </div>
    )
}

export default Home
