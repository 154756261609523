import React from 'react';
import {Link} from 'react-router-dom';
import routes from '../resources/json/routes.json'

function Navbar(props) {
    return (
        <React.Fragment>
            <p/>
            <div className="row" id="navRow">
                {/* {props.currentPath !== routes.client.home &&
                    <div className="navLink navLeftBuffer">
                        <Link to={routes.client.home}>Home</Link>
                    </div>
                }
                {props.currentPath !== routes.client.about &&
                    <div className="navLink navLeftBuffer">
                        <Link to={routes.client.about}>About</Link>
                    </div>
                }
                {props.currentPath !== routes.client.contact &&
                    <div className="navLink navLeftBuffer">
                        <Link to={routes.client.contact}>Contact</Link>
                    </div>
                } */}
                <div className="navLink navLeftBuffer">
                        <Link to={routes.client.home}>Home</Link>
                </div>
                <div className="navLink navLeftBuffer">
                        <Link to={routes.client.about}>About</Link>
                </div>
                <div className="navLink navLeftBuffer">
                        <Link to={routes.client.contact}>Contact</Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Navbar
