import React from "react";
import '../../index.css'


function VList(props) {

    const Clicked = () => {
        props.onClick(props.index);
    }

    return(
        <li key={props.index} style={{listStyle: "none", textAlign: "left", marginBottom:'0.5em' }}>
            <button
            className="tab_button"
            onClick={Clicked}
            style={
                props.activeTabId === props.index ? {color: "#000000", fontWeight:800} : {color: "#8892b0", fontWeight:200, fontSize:'15px'}
            }
            >
                { props.data}
            </button>
        </li>
    )
}

export default VList;