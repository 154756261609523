import React from 'react';
import Footer from './Footer';
import HttpService from '../services/HttpService';
import routes from '../resources/json/routes.json';

function Contact() {

    const [subject, setSubject] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [emailBody, setEmailBody] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');

    const onChangeSubject = e => {
        setSubject(e.target.value);
    };

    const onChangeEmail = e => {
        setEmail(e.target.value);
    };

    const onChangeName = e => {
        setName(e.target.value);
    };

    const onChangeEmailBody = e => {
        setEmailBody(e.target.value);
    };

    const onClickSubmit = e => {
        setErrorMessage('');
        setSuccessMessage('');
        HttpService.post(routes.server.root + routes.server.contactFormSubmitPost, { subject, email, name, emailBody })
            .then(res => {
                if (!res.data.err) {
                    setSuccessMessage(res.data.message);
                } else {
                    setErrorMessage('Unable to send email.  Please try again later');
                }
            })
            .catch(err => {
                setErrorMessage(err.response.data.message);
            });
    };

    return (
        <React.Fragment>
            <div className="spacer"/>
            <h3 className="leftBuffer">
                Contact Us
            </h3>
            <div className="spacer"/>
            <div className="form-group contactFormLayoutSm">
                <label htmlFor="subjectInput">Subject:</label>
                <input type="text" className="form-control" id="subjectInput" placeholder="Enter subject" value={subject} onChange={onChangeSubject}/>
            </div>
            <p/>
            <div className="form-group contactFormLayoutSm">
                <label htmlFor="emailInput">Email Address:</label>
                <input type="email" className="form-control" id="emailInput" placeholder="Enter email" value={email} onChange={onChangeEmail}/>
            </div>
            <p/>
            <div className="form-group contactFormLayoutSm">
                <label htmlFor="nameInput">Name:</label>
                <input type="text" className="form-control" id="nameInput" placeholder="Enter name" value={name} onChange={onChangeName}/>
            </div>
            <p/>
            <div className="form-group contactFormLayout">
                <label htmlFor="emailBodyInput">Message:</label>
                <textarea className="form-control" id="emailBodyInput" rows="3" value={emailBody} onChange={onChangeEmailBody}></textarea>
            </div>
            <p/>
            {successMessage !== '' &&
                <React.Fragment>
                    <div className="col-12 greenText textAlignCenter">{successMessage}</div>
                    <p/>
                </React.Fragment>
            }
            {errorMessage !== '' &&
                <React.Fragment>
                    <div className="col-12 redText textAlignCenter">{errorMessage}</div>
                    <p/>
                </React.Fragment>
            }
            <div id="sendContactButton" onClick={onClickSubmit}>
                Send
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default Contact
