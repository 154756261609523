
const PageTracker = () => (
    <div className="pageTrackerContainer">
        <a href='https://clustrmaps.com/site/1bwvv'  title='Visit tracker' target="_blank">
            <img src='//clustrmaps.com/map_v2.png?cl=ffffff&w=a&t=tt&d=vxD1cNdAWdQZvVpzsNCf5baFPRERUh1Qy6vSS3PdsQ4'/>
        </a>    
    </div>
)


export default PageTracker;