import React from 'react'

function DevelopmentBanner() {
    return (
        <div className="row justify-content-center developmentBanner">
            <div className="col-3 textAlignCenter">DEVELOPMENT</div>
            <div className="col-3 textAlignCenter">DEVELOPMENT</div>
            <div className="col-3 textAlignCenter">DEVELOPMENT</div>
            <div className="col-3 textAlignCenter">DEVELOPMENT</div>
        </div>
    )
}

export default DevelopmentBanner
