import React, {useState} from "react";
import {Container, Row, Col } from "reactstrap";
import VList from "./VList";
import "../../index.css"


function VerticalTab(props){
    const [activeTabId, setActiveTabId] = useState(0)


    const btnClick = (id) => {
        setActiveTabId(id);
        props.setAnimate(false)
        if (id === 0){
            props.setIsFirstTabSelected(true);
            props.setIsSecondTabSelected(false);
            props.setIsImmunoData(false);
        } else if(id === 1){
            props.setIsFirstTabSelected(false);
            props.setIsSecondTabSelected(true);
            props.setIsImmunoData(false);
        } else if (id === 3){
            props.setIsImmunoData(true);
            props.setIsSecondTabSelected(false);
            props.setIsFirstTabSelected(false);
        } else {
            props.setIsFirstTabSelected(false);
            props.setIsSecondTabSelected(false);
            props.setIsImmunoData(false);
        }
        props.resetTabText();
    }

    const searchTypes = ["Single Gene Search (Pan Cancer)", "Two Gene Search (Pan Cancer)", "Multi Gene Search (Pan Cancer)", "Immunotherapy datasets"]

    return (
        <div className="search-container">
        {/* <Container classname="section_search-container"> */}
        
            {/* <Row classname="flex-fill justify-content-center" > */}
                {/* <Col className="flex-fill" sm="5"> */}
                <div className="section_search-container-flex">
                    <div className="tab-column">
                
                        <div classname="section_search-styledTab">
                            <ul classname="section_search-styledTabList">
                                {searchTypes.map((searchType, index) =>(
                                    <VList
                                    key={index}
                                    onClick={btnClick}
                                    data={searchType}
                                    index={index}
                                    activeTabId={activeTabId}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                {/* </Col> */}
                {/* <Col className="flex-fill" sm="8"> */}
                <div className="searchBar-column">
                    {searchTypes.map((searchType, index) =>(
                        <div
                        key={index}
                        style={
                            activeTabId === index ? {display: "block"} : {display:"none"}
                        }
                        >
                            <div classname="searchHeaderContainer">
                                <h4 style={{textAlign:'center'}}> 
                                    {searchType} 
                                </h4>
                            </div>
                            {props.children}
                        </div>
                    ))}
                </div>
            </div>
                {/* </Col> */}
            {/* </Row> */}
            <span
            classname={activeTabId === 0 ? "index1-chosen": activeTabId === 1 ? "index2-chosen": activeTabId === 2 ? "index3-chosen": "index4-chosen"}
            >
            </span>
        {/* </Container> */}
        </div>

    )
}



export default VerticalTab;