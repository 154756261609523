import React, { useEffect } from 'react'
import {DELIMITER} from '../resources/constants'

function MostPopularSearchedGenes(props) {

    const EVEN_ROW_CLASS = 'evenRow';
    const ODD_ROW_CLASS = 'oddRow';
    const GENEID_QUERYSTRING_FIELD = 'geneid';

    const [popularGeneList, setPopularGeneList] = React.useState([]);

    useEffect(() => {
        if (props.popularGeneList && props.popularGeneList.length > 0) {
            setPopularGeneList(props.popularGeneList);
        }
    }, [props.popularGeneList]);

    useEffect(() => {
        if (props.socketInst) {
            props.socketInst.on('populargenes', geneList => {
                setPopularGeneList(geneList);
            });
        }
    }, [props.socketInst]);

    return (
        <div className="col-3 searchedGenesContainer">
            <div className="searchedGenesHeader">
                Popular Gene Searches
            </div>
            {popularGeneList && popularGeneList.length > 0 &&
                popularGeneList.map((gene,i) => {
                    let classname = EVEN_ROW_CLASS;
                    i % 2 === 0 ? classname = EVEN_ROW_CLASS : classname = ODD_ROW_CLASS;
                    if (gene.includes(DELIMITER)) {
                        return <div key={i}><a className={classname} href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_MULTI_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + gene} target="_blank" rel="noreferrer">{gene}</a></div>
                    }
                    return <div key={i}><a className={classname} href={process.env.REACT_APP_SHINY_HOST + ':' + process.env.REACT_APP_SHINY_PORT + '/' + process.env.REACT_APP_SHINY_SINGLE_ROUTE + '/?' + GENEID_QUERYSTRING_FIELD + '=' + gene} target="_blank" rel="noreferrer">{gene}</a></div>
                })
            }
        </div>
    )
}

export default MostPopularSearchedGenes
